<template>
  <div class="widget-custom-in" v-if="!countryId">
    <selectComponent class="mr-6" placeholder="Выберите страну" :options="countryList" v-model="selectCountry" />
    <ButtonStock title="Перейти" @click="goTo" />
  </div>
  <div class="widget-custom-in hotIndocators" v-else>
    <div class="select">
      <select-component placeholder="Период" :options="yearsHotIndicators" />
    </div>
    <ul class="hotIndocators-list">
      <li class="hotIndocators-item">
        <p class="title">Экспорт</p>
        <div class="data positive">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">2,5843</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
      <li class="hotIndocators-item">
        <p class="title">Импорт</p>
        <div class="data negative">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">1,4452</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
      <li class="hotIndocators-item">
        <p class="title">Товарооборот</p>
        <div class="data positive">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">12,584</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
      <li class="hotIndocators-item">
        <p class="title">Сальдо</p>
        <div class="data negative">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">0,4583</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
      <li class="hotIndocators-item">
        <p class="title">Инвестиции поступившие</p>
        <div class="data negative">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">5,5483</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
      <li class="hotIndocators-item">
        <p class="title">Инвестиции накопленные</p>
        <div class="data positive">
          <icon-component name="arrowIndicator" />
          <p class="bigNumber">1,8954</p>
          <p class="smallNumber"><span>+50%</span>, 20.03.22 млрд.₽</p>
        </div>
      </li>
    </ul>
    <ButtonStock title="Страновая аналитика" />
  </div>
</template>

<script>
  import selectComponent from '@/common/components/selectComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'Widget4',
    components: {
      selectComponent,
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        countryList: [],
        selectCountry: null,

        yearsHotIndicators: [
          { text: '2021', valve: 1 },
          { text: '2022', valve: 2 },
          { text: '2023', valve: 3 },
          { text: '2024', valve: 4 },
        ],
      };
    },
    created() {
      Utils.loadSelectOptions('countryList', this.countryList);
    },
    methods: {
      goTo() {
        if (this.selectCountry > 0) {
          this.$router.push({ name: 'countryAnalytics', query: { countryId: this.selectCountry } });
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
